*{
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  background-image: url("./assets/images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}
